<div class="resource-list-container">
  <span style="background: #64CC44;
          border-radius: 20px;
          color: #64CC44;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
  <h5 style="font-weight: bold">The Team</h5>
  <div class="d-flex flex-row justify-content-center h-100 w-100">
    <div class="resource-container">
      <carousel style="margin-bottom: 10px;">
        <slide>
          <div class="d-flex w-100">
            <div class="hr-team" style="background-color:#FFF;">
              <img
                [src]="staff[0].profile_picture_url ? staff[0].profile_picture_url : 'assets/new_placeholder.png'"
              >
              <div class="d-flex flex-column align-items-center justify-content-center text-center" style="width: fit-content;">
                <p class="header-name">{{staff[0].name}}</p>
                <p class="position-title">{{(staff[0].job_title.length > 20) ? (staff[0].job_title | slice: 0: 20) + '...' : (staff[0].job_title)}}</p>
              </div>
            </div>
        </div>
        </slide>
      </carousel>
    </div>
  </div>
</div>
