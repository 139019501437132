import { Component, OnInit, Input } from '@angular/core';
import {HrCategory} from '../../core/models/hr-category';
import {IResource2} from '../../core/models/resource2';


@Component({
  selector: 'jz-security-operations-documents',
  templateUrl: './security-operations-documents.component.html',
  styleUrls: ['./security-operations-documents.component.scss']
})
export class SecurityOperationsDocumentsComponent implements OnInit {

  @Input() securityOperationsDocumentsArray: IResource2[];
  isEmpty: boolean;

  constructor() { }

  ngOnInit(): void {
    this.ifEmpty();
  }

  ifEmpty() {
    if (this.securityOperationsDocumentsArray.length) {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }
}
