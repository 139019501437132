<div class="grey-section p-4 card-height" id="events-card" *ngIf="globalEvents">

  <!-- Spinner start -->
  <ngx-spinner
    bdColor="rgba(51,51,51,0.3)"
    size="large"
    color="white"
    type="ball-climbing-dot"
    [fullScreen]="true"
  ></ngx-spinner>
  <!-- Spinner end -->

  <span style="background: #E63A95;
          border-radius: 20px;
          color: #E63A95;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 28px">-</span>
  <!-- <h5>Events & Travel</h5> -->

  <!-- adding select form to pick the event's year -->
  <!-- <mat-form-field>
    <mat-label style="margin-left: 10px; margin-top: 50px">Event Year</mat-label>
    <mat-select [(value)]="selected">
      <mat-option (click)="onChange()">None</mat-option>
      <mat-option (click)="onChange()" value="2023">2023</mat-option>
      <mat-option (click)="onChange()" value="2022">2022</mat-option>
      <mat-option (click)="onChange()" value="2021">2021</mat-option>
      <mat-option (click)="onChange()" value="2020">2020</mat-option>
      <mat-option (click)="onChange()" value="2019">2019</mat-option>
      <mat-option (click)="onChange()" value="2018">2018</mat-option>
    </mat-select>
  </mat-form-field> -->


  <div class="links-container">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row justify-content-center align-items-center">
        <img class="jazwares-travel-logo" src="../../../assets/jtraining/Jazwares-Travel.png" alt="Jazwares Travel logo">
      </div>
      <ul>
        <!-- <li class="list-space"><a href="https://portal.amexgbt.com/sign-up/JZWR" target="_blank">US Based Travelers: New Account Registration</a></li> -->
        <!-- <li class="list-space"><a href="https://access.amexgbt.com/" target="_blank">US Based Travelers: Log In</a></li> -->
        <!-- <li class="list-space"><a href="https://app.smartsheet.com/b/form/3d91312860f0468bb53f377a6287584c" target="_blank">International Based Travelers: Travel Request Form</a></li> -->
        <li class="list-space"><a href="https://drive.google.com/file/d/1reZoomDyQH8K3ACZBN6watbtoaKyGPNr/view" target="_blank">Jazwares Travel Policy</a></li>
        <li class="list-space"><a href="https://helpdesk.jazportal.com/hc/en-us/requests/new?ticket_form_id=28260767108375" target="_blank">Travel Request Form</a></li>
        <li class="list-space">Need to reach the travel team? Email <span style="color:blue">trips@jazwares.com</span></li>
        <!-- <li><a href="https://drive.google.com/file/d/1cK2hoj2sMISW0lc2wxv7ohs_jjGHGN0g/view?usp=sharing" target="_blank">US Based Travels: AMEX/GBT Training Video</a></li> -->
        <!-- <li><a href="https://app.smartsheet.com/b/home?lgt=wf&lrt=s&ss_v=282.0.0&EQBCT=37ae2ed5de0c4686b5a2c7bab1c8ff09" target="_blank">California Offices Visitor Form</a></li> -->
      </ul>
    </div>
  </div>

</div>


<!-- <div class="content-container">
  <div class="row">

    <div class="col-sm-12 col-md-12 col-lg-12" *ngFor="let event of globalEvents">
      <div class="row" style="margin-bottom: 25px">

        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="d-flex flex-column">
            <p class="event-day">{{convertStringtoDateObj(event.datetime)}}</p>
            <p class="event-date">{{convertStringToMonth(event.datetime)}}, {{convertStringToFullYear(event.datetime)}}</p>
          </div>
        </div>

        <div class="col-sm-8 col-md-8 col-lg-8" id="content-area">
          <div class="d-flex flex-column">
            <p>{{event.name}}</p>

            <a  class="link-button"
                *ngIf="event.event_type === 'gallery'"
                [target]="(event.event_type === 'gallery') ? '' : '_blank'"
                [routerLink]="event.event_type === 'gallery' ? ['/jevent/', event.id] : 'null'"
                [ngStyle]="{
                    'background-color': '#E6258B',
                    'border-color': '#E6258B'
                  }"
            >
              {{event.event_type === 'gallery' ? 'gallery' : event.external_link_cta ? event.external_link_cta : ''}}
            </a>

            <a  class="link-button"
                *ngIf="event.event_type !== 'gallery'"
                [href]="event.event_type === 'pdf' ? event.pdf_url : (event.event_type === 'link') ? event.external_link : ''"
                [target]="(event.event_type === 'gallery') ? '' : '_blank'"
                [ngStyle]="{
                    'background-color': '#54C8E8',
                    'border-color': '#54C8E8'
                  }"
            >
              {{event.event_type === 'gallery' ? 'gallery' : event.external_link_cta ? event.external_link_cta : ''}}
            </a>
          </div>
        </div>

        <mat-divider></mat-divider>

      </div>
    </div>

  </div>
</div> -->
