import { Component, HostListener, OnInit } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {PaginatedPosts2ListResponse, Posts2} from '../core/models/paginatedPosts2ListResponse';
import {IResource2} from '../core/models/resource2';
import {Posts2Service} from '../core/posts2.service';
import {Resources2Service} from '../core/resources2.service';


@Component({
  selector: 'jz-jwellness',
  templateUrl: './jwellness.component.html',
  styleUrls: ['./jwellness.component.scss'],
})
export class JwellnessComponent implements OnInit {
  cat = 'jwellness';
  paginatedPosts2ListResponse: PaginatedPosts2ListResponse;
  listOfPosts2: Posts2[];
  filteredPosts: Posts2[];
  catForResources = 'jwellness';
  resources2: IResource2[];
  page = 1;
  totalPages: number;
  errorMessage = '';
  mh_icon = '../../assets/jwellness/Mental_Health_Icon.png';
  nu_icon = '../../assets/jwellness/Nutrition_Icon.png';
  pa_icon = '../../assets/jwellness/Physical Activity_Icon.png';
  fw_icon = '../../assets/jwellness/Financial Wellness_Icon.png';
  br_icon = '../../assets/jwellness/Book Rec_Icon.png';
  jwellnessLogo = '../../assets/jwellness/JWellness_Logo_Jazmanian.png';
  flowerGraphic = '../../assets/jwellness/Flower Graphic_Right Side.png';

  categorySelected = false;
  selCategoryIcon=this.jwellnessLogo;
  selCategoryTitle='JWellness';
  categoryFilter ="";

  innerWidth = window.innerWidth;

  constructor(
    private posts2Service: Posts2Service,
    private resourceService: Resources2Service,
    private spinner: NgxSpinnerService
  ) {}

  @HostListener('window:resize')
  onresize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    console.dir(this.mh_icon);
    this.fetchPosts2();
    this.fetchResources2();
  }

  setCategoryIcon() {
    return this.selCategoryIcon
  }
  private fetchResources2() {
    this.resourceService.getResources2ByCat(this.catForResources).subscribe({
      next: (response) => {
        if (response.length) {
          this.resources2 = response;
          // console.log(this.resources2);
        }
      },
    });
  }

  private fetchPosts2() {
    this.spinner.show();
    this.posts2Service
      .getPosts2PaginatedByCategory(this.cat, this.page)
      .subscribe({
        next: (response) => {
          this.paginatedPosts2ListResponse = response;
          // this.posts2List = response[0].posts;
          // console.log('nothing to show?', this.paginatedPosts2ListResponse);
          this.listOfPosts2 = this.paginatedPosts2ListResponse.posts;
          this.totalPages = this.paginatedPosts2ListResponse.total_pages;
          this.spinner.hide();
        },
        error: (err) => (this.errorMessage = err),
      });
  }

  isWellnessCategorySelected() {
    return this.categorySelected;
  }
  selectCategory(selCategory:string) {
    
    switch(selCategory){
      case 'mh':
        this.selCategoryTitle = 'Mental Health';
        this.selCategoryIcon = this.mh_icon;
        this.categoryFilter = '<!--#mentalhealth-->';

        break;
      case 'nu':
        this.selCategoryTitle = 'Nutrition';
        this.selCategoryIcon = this.nu_icon;
        this.categoryFilter = '<!--#nutrition-->';
        break;
      case 'pa':
        this.selCategoryTitle = 'Physical Activity';
        this.selCategoryIcon = this.pa_icon;
        this.categoryFilter = '<!--#physicalactivity-->';
        break;
      case 'fw':
        this.selCategoryTitle = 'Financial Wellness';
        this.selCategoryIcon = this.fw_icon;
        this.categoryFilter = '<!--#financialwellness-->';
        break;
      case 'br':
        this.selCategoryTitle = 'Book Recommendations';
        this.selCategoryIcon = this.br_icon;
        this.categoryFilter = '<!--#bookrecommendations-->';
        break;
      default:
        this.selCategoryTitle = 'jwellness';
        this.selCategoryIcon = this.jwellnessLogo;
        this.categoryFilter = '';
    }
    this.filterPosts();
    this.categorySelected = true;

  }
  filterPosts() {
    this.filteredPosts  = this.listOfPosts2.filter((post) => {
      return post.text_content.includes(this.categoryFilter);
    })
  }
}
