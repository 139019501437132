<section id="right-content">
  <div class="container" #securityOperationsContainer>
    <!-- Spinner start -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.3)"
      size="large"
      color="white"
      type="ball-climbing-dot"
      [fullScreen]="true"
    ></ngx-spinner>
    <!-- Spinner end -->
    <div class="container" *ngIf="dataLoaded">
      <div class="row">
        <!--left side-->
        <div class="col-sm-12 col-md-6">
          <div class="d-flex flex-column gap-3">
            <!-- Emergency Phone -->
             <div class="d-flex bg-white justify-content-center align-items-center" style="border-radius: 20px;margin-bottom: 30px; padding: 10px; height: 175px;overflow: hidden;">
              <img [src]="jazmanianPolice" style="width: 220px; height: 220px; margin-left: -110px; margin-top:70px; transform: rotate(17deg);">
              <div>
                <h5 style="font-weight: bold">EMERGENCY PHONE:</h5>
                <h2 style="font-weight: bolder">954.862.7107</h2>
              </div>
             </div>
            <!--latest updates-->
            <div class="d-flex justify-content-center flex-fill mb-4">
              <jz-security-operations-latest-updates class="w-100" [posts2List]="posts2List"></jz-security-operations-latest-updates>
            </div>
            
          </div>
        </div>

        <!--right side-->
        <div class="col-sm-12 col-md-6">
          <div style="background-color: #FFFFFF; border-radius: 20px; padding: 10px; min-height: 367px;">
            <span style="background: #64CC44;
              border-radius: 20px;
              color: #64CC44;
              position: absolute;
              left: 5px;
              width: 26px;
              height: 14px;
              top: 18px">-</span>
            <h5 style="font-weight: bold">Important Documents</h5>
            <div class="box-for-accordion h-50" style="position: relative; padding: 10px">
              <div style="overflow-y: auto; overflow-x: hidden; height: 100%">
                <mat-accordion id="{{'hr-acc-' + category.id}}" class="jResources-custom-mat-accordion" *ngFor="let category of securityCategories; index as i">
                <!--[expanded]="i === 0"-->
                  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{category.cat}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let resource of category.resources">
                      <a class="resource-container" [href]="(
                      resource.resource_type === 'pdf' ||
                      resource.resource_type === 'xlsx' ||
                      resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                         [target]="'_blank'"
                      >
                        <img style="width: 10%" alt="file type" [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"/>
                        <p>{{resource.name}}</p>
                      </a>
                    </div>

                    <!-- region second accordion for subcategories-->
                    <div style="margin-top: 20px">
                      <mat-accordion *ngFor="let subCat of category.sub_cat; index as i">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{subCat.cat}}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div *ngFor="let resource of subCat.resources">
                            <a class="resource-container" [href]="(
                            resource.resource_type === 'pdf' ||
                            resource.resource_type === 'xlsx' ||
                            resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                               [target]="'_blank'"
                            >
                              <img style="width: 10%" alt="file type" [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"/>
                              <p>{{resource.name}}</p>
                            </a>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                    <!--region end second accordion ends-->

                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
          <!--meet your hr team-->
          <div class="d-flex justify-content-center flex-fill" style="margin-top: 30px; height: 220px">
            <jz-security-operations-team class="w-100" [staff]="staff"></jz-security-operations-team>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
