import { Component, OnInit, Input } from '@angular/core';
import {Posts2} from '../../core/models/paginatedPosts2ListResponse';

@Component({
  selector: 'jz-security-operations-latest-updates',
  templateUrl: './security-operations-latest-updates.component.html',
  styleUrls: ['./security-operations-latest-updates.component.scss']
})
export class SecurityOperationLatestUpdatesComponent implements OnInit {
  @Input() posts2List: Posts2[];
  // Security Posts
  cat = 'Security Operations';

  constructor() { }

  ngOnInit(): void {
  }

}
