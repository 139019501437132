<div class="resource-list-container">
  <span style="background: #64CC44;
          border-radius: 20px;
          color: #64CC44;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
  <h5 style="font-weight: bold">Latest Updates</h5>
  <div class="resource-container d-flex flex-row justify-content-center align-items-center">
<!--      <img class="img-fluid" src="assets/human-resources/news-01.jpg" alt="user-logo">-->
    <carousel id="hr-latest-carousel">
      <slide *ngFor="let slide of posts2List">
        <div class="hr-team d-flex flex-row justify-content-center align-items-center" style="width: 465px; height: 322px">
          <img
            [routerLink]="['/posts', slide.id]"
            [src]="slide.card_image ? slide.card_image : 'assets/human-resources/latest-updates/latest-update-banner.jpg'"
            alt="user-logo"
          >
        </div>
      </slide>
    </carousel>
  </div>
</div>


