import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'jz-security-operations-team',
  templateUrl: './security-operations-team.component.html',
  styleUrls: ['./security-operations-team.component.scss']
})
export class SecurityOperationsTeamComponent implements OnInit {

  // @Input() staff: HrTeamStaff[];
  @Input() staff: {name:string;job_title:string;profile_picture_url:string}[];
  indexPosition = 0;

  constructor() { }

  ngOnInit(): void {
  }

  // public functions
  nextUser() {
    const newIndex = this.indexPosition + 1;
    this.indexPosition = newIndex;
  }

  previousUser() {
    if (this.indexPosition !== 0) {
      const newIndex = this.indexPosition - 1;
      this.indexPosition = newIndex;
    }
  }

}
