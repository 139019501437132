<div class="resource-list-container h-100">
  <span style="background: #9B46BB;
          border-radius: 9px;
          color: #9B46BB;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
  <h5 style="font-weight: bold">Security Operations Center</h5>
  <div id="content-cont" *ngIf="!isEmpty">
    <div class="row">
      <div class="col-md-12 col-xl-6 show-data" *ngFor="let resource of securityOperationsDocumentsArray[0].resources">
        <a
          class="resource-container"
          [href]="(
          resource.resource_type === 'pdf' ||
          resource.resource_type === 'xlsx' ||
          resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
          [target]="'_blank'"
        >
          <img style="width: 22%"
            alt="file type"
            [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"
          />
          <p>{{resource.name}}</p>
        </a>
      </div>
    </div>
  </div>
</div>
