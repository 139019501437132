<section *ngIf="employee" class="employee-profile-menu-cont-styles d-flex flex-column">

  <!--first container-->
  <div class="d-flex flex-row first-container-styles">
    <img class="employee-profile-menu-img" [src]="employee.profile_picture_url ? employee.profile_picture_url :
    'assets/org-chart/pic-placeholder.png'" alt="employee profile pic">
    <div class="d-flex flex-column justify-content-center name-position-cont">
      <p class="employee-name">{{employee.name}}</p>
      <span class="grey-extra-info-text">{{employee.job_title}}</span>
    </div>
    <!--to close modal-->
    <img class="clear-button-right-side-modal" src="assets/new-org-chart/Clear.png" (click)="closeDialog()"
         alt="clear icon">
  </div>

  <!--second container-->
  <div class="d-flex flex-row flex-wrap second-container-styles">
    <div *ngIf="employee.location" class="d-flex flex-column">
      <p class="menu-options-text-styles">Location</p>
      <span class="grey-extra-info-text">{{employee.location}}</span>
    </div>
    <div *ngIf="employee.department" class="d-flex flex-column">
      <p class="menu-options-text-styles">Department</p>
      <span class="grey-extra-info-text">{{employee.department}}</span>
    </div>
    <div *ngIf="employee.division" class="d-flex flex-column">
      <p class="menu-options-text-styles">Division</p>
      <span class="grey-extra-info-text">{{employee.division}}</span>
    </div>
    <div *ngIf="employee.email" class="d-flex flex-column">
      <p class="menu-options-text-styles">Email</p>
      <span class="grey-extra-info-text">
        <a href="mailto:{{employee.email}}" target="_top">{{employee.email}}</a>
      </span>
    </div>
    <a class="fun-facts-link d-flex flex-column justify-content-center align-items-center" (click)="onClick()">Fun Facts</a>
    <a class="report-link d-flex flex-column justify-content-center align-items-center"
       href="https://jazwares.employee.hrsd.ultipro.com/request-forms/i-want-to-report-something-in-j-cosmos"
       target="_blank">Report</a>
  </div>

  <span class="line-divider"></span>

  <!--third container-->
  <div class="block-third-cont">
    <div class="third-container-styles d-flex flex-column">
      <div class="peers-container">

        <!--Reports to:-->
        <div *ngIf="employee.manager" class="item-list d-flex flex-column">
          <span class="grey-extra-info-text">Reports to</span>
          <div class="d-flex flex-column peers-node-list">
            <div *ngIf="manager" class="d-flex flex-row node-cont-style" (click)="searchForNewSetOfData(manager.number)"
                 [ngStyle]="{'background-color' : peersBackgroundColor}">
              <img class="person-img-style" [src]="manager.profile_picture_url ? manager.profile_picture_url :
              'assets/org-chart/pic-placeholder.png'" alt="person profile">
              <div class="d-flex flex-column justify-content-center">
                <p class="person-name">{{manager.name}}</p>
                <span class="person-title">{{manager.job_title}}</span>
              </div>
            </div>
          </div>
        </div>

        <!--Team:-->
        <div *ngIf="employee.manages.length" class="item-list d-flex flex-column">
          <span class="grey-extra-info-text">Team</span>
          <div *ngFor="let element of newManagesDataArray" class="d-flex flex-column peers-node-list">
            <jz-peers-node [elemNumber]="element" [color]="reportsBackgroundColor"
                           (newTest)="receivedNewTest($event)">

            </jz-peers-node>
          </div>
        </div>


      </div>
    </div>
  </div>


<!--  <span class="line-divider"></span>-->

  <!--fun facts-->
  <div *ngIf="funFacts" #anchor class="block-fourth-cont">
    <div class="block-fourth-cont-styles d-flex flex-column">
      <div *ngFor="let fact of funFacts" class="d-flex flex-column">
        <span *ngIf="fact.component_tagline" class="question-tagline-cont d-flex flex-row justify-content-center align-items-center">{{fact.component_tagline}}</span>
        <p class="question-prompt-styles">{{fact.component_prompt}}</p>
        <span class="answer-prompt-styles">{{fact.response}}</span>
      </div>
    </div>
  </div>

</section>

