import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Employee} from '../../new-org-chart/models/employee';
import {AuthorizationService} from '../../core/authorization.service';
import {Router} from '@angular/router';


@Component({
  selector: 'jz-logged-user-profile',
  templateUrl: './logged-user-profile.component.html',
  styleUrls: ['./logged-user-profile.component.scss']
})
export class LoggedUserProfileComponent implements OnInit {
  userData: Employee[];
  placeholderImg = "../../../assets/placeholder.jpg";
  

  constructor(private router: Router, private googleLoginService: AuthorizationService, private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) data) {this.userData = data.user; }

  ngOnInit(): void {
    console.log('checking if data is coming', this.userData);
    if (this.userData[0].profile_picture_url == null || this.userData[0].profile_picture_url == "") {
      this.userData[0].profile_picture_url = "../../../assets/placeholder.jpg";
    }
  }

  logOut() {
    this.googleLoginService.logOut();
    this.router.navigate(['/login']);
  }

  goTo() {
    this.router.navigate(['/user-profile']);
    this.dialog.closeAll();
  }

}
