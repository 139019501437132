<div id="right-content">
  <div class="container" style="padding-left: 40px">
    <div class="row">

      <div class="col-sm-12 col-md-12 col-lg-7 white-section card-height mr-0 mr-md-4 mr-lg-0">
        <span style="background: #E6258B;
                    border-radius: 20px;
                    color: #E6258B;
                    position: absolute;
                    left: -9px;
                    width: 26px;
                    height: 14px;
                    top: 28px">-</span>
        <h5>Calendar</h5>

        <div class="d-flex flex-wrap justify-content-end ">
          <!-- holiday's calendar -->
          <div class="hol-cal" style="margin-right: 10px">
            <a class="text-link" href="https://drive.google.com/file/d/1dhELpnrTXqi62hgD79GABQh2_oFCdNQa/view?usp=drive_link"
               [target]="'_blank'"
            >
              Holiday Calendar
            </a>
          </div>

          <!-- new form link-->
          <div class="hol-cal">
            <a class="text-link" href="https://app.smartsheet.com/b/form/8b1d52eb35604a5783c1ce8285954d2c"
               [target]="'_blank'"
            >
              Event or Team Meeting
            </a>
          </div>
        </div>


        <div class="row flex-center" id="calendar-cont">
          <iframe
            src="https://calendar.google.com/calendar/embed?src=jcosmos%40jazwares.com&ctz=America%2FNew_York"
            width="97%"
            height="400"
            class="calendar-iframe"
            style="margin-top: 30px; height: 700px"
          ></iframe>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-5" style="padding-top: 10px">
        <jz-global-events></jz-global-events>
      </div>
    </div>
  </div>
</div>
