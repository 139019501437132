<div id="right-content">
  <div class="container wellness-cont">

    <!-- Spinner start -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.3)"
      size="large"
      color="white"
      type="ball-climbing-dot"
      [fullScreen]="true"
    ></ngx-spinner>
    <!-- Spinner end -->
    <div *ngIf="isWellnessCategorySelected()" class="selected-wellness-cont">
      <div class="row">
        <div class="col-12">
          <mat-card class="card-mat selected-outer">
            <div class="selectedCategoryCont">
              <div class="selectedCategoryImgCont ">
                <img class="selectedCategoryImg" mat-card-image [src]="selCategoryIcon" alt="card image">
              </div>
              <div class="selectedCategoryTxtCont">
                <div><p class="selectedCategoryTxt">{{selCategoryTitle}}</p></div>
              </div>
             
            
          
            </div>
          </mat-card>
            <!-- <mat-card class="card-mat selectedCategoryCont">
            <div class="selectedCategoryImgCont">
              <img class="selectedCategoryImg" [src]="mh_icon" alt="selected category image"></div>
            <div class="selectedCategoryTxtCont wellness-icon-txt-cont">
              <p class="selectedCategoryTxt wellness-icon-txt">Physical Activity</p>
            </div>
            </mat-card> -->
          <!-- <div class="selectedCategoryImgCont card-mat">
            <img class="selectedCategoryImg" [src]="mh_icon" alt="selected category image">
          </div> -->
        </div>
      </div>

      <div class="selectedCategoryBackCont">
        <div>
          <p class="selectedCategoryBackTxt" (click)="categorySelected=false">Go back</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-0 mt-4 mt-md-0">
          <div class="card-height left-card">
            <div class="img-container text-center"></div>
            <div class="card-body">
              <p class="card-title">JWellness</p>
              <p class="card-text">
                Find resources to support your mental and physical health and well-being.
              </p>
              <div class="cont-content-scroll" *ngIf="resources2">
                <div *ngFor="let resource of resources2[0].resources">
                  <a class="resource-container" [href]="(
                                        resource.resource_type === 'pdf' ||
                                        resource.resource_type === 'xlsx' ||
                                        resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                    [target]="'_blank'" style="text-decoration: none; color: black;">
                    <img alt="file type"
                      [src]="resource.resource_type === 'pdf' ? 'assets/resources-colored-icon.png' : 'assets/links-logo.png'" />
                    <p>{{resource.name}}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-8 mt-0 mt-4 mt-md-0">
                    
          <jz-common-post-list [listOfPosts2]="filteredPosts" [resources2]="resources2" [totalPages]="totalPages"
                              [page]="page" [cat]="cat"></jz-common-post-list>
        </div> 
        
      </div>
    </div>

    <div *ngIf="!isWellnessCategorySelected()" class="main-cont">
      <div class="row">
        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-0 mt-4 mt-md-0">
          <div class="card-height left-card">
            <div class="img-container text-center"></div>
            <div class="card-body">
              <p class="card-title">JWellness</p>
              <p class="card-text">
                Find resources to support your mental and physical health and well-being.
              </p>
              <div class="cont-content-scroll" *ngIf="resources2">
                <div *ngFor="let resource of resources2[0].resources">
                  <a class="resource-container" [href]="(
                                resource.resource_type === 'pdf' ||
                                resource.resource_type === 'xlsx' ||
                                resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                    [target]="'_blank'" style="text-decoration: none; color: black;">
                    <img alt="file type"
                      [src]="resource.resource_type === 'pdf' ? 'assets/resources-colored-icon.png' : 'assets/links-logo.png'" />
                    <p>{{resource.name}}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--NEW card show -->
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-7 mt-0 mt-4 mt-md-0"
        
        >
          <div class="row">
            <div class="col-12">
              <mat-card class="card-mat carousel">
                <div class="d-flex justify-content-center align-items-center flex-fill mb-4 carousel-cont">
                  <div id="carousel-height">
                    <carousel>
                      <slide *ngFor="let post of listOfPosts2">
                        <img class="slide-img" [routerLink]="['/posts', post.id]" alt="first slide" src="{{post.card_image}}" />
                        <div id="slider-title">
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col-md-8 col-sm-12">
                                <p class="filsonProMed">{{(post.headline.length > 30) ? (post.headline | slice:0:30) + '...' :
                                  (post.headline)}}</p>
                              </div>
                              <div class="col-md-4 col-sm-12 text-right">
                                <span [routerLink]="['/posts', post.id]" class="filsonProMed link-style" style="cursor: pointer">Read
                                  more >
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </slide>
                    </carousel>
                  </div>
                
                </div>
              </mat-card>
              
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <mat-card class="card-mat mental-health">
                    <div class="wellness-cards-cont ">
                      <div class="wellness-icon-img-cont">
                        <img class="wellness-icon-img" mat-card-image [src]="mh_icon" alt="card image">
                      </div>
                      <div class="wellness-icon-txt-cont">
                        <p class="wellness-icon-txt">Mental Health</p>
                      </div>
                      <div class="wellness-icon-btn-cont">
                        <button type="button" class="recog-button " (click)="selectCategory('mh')"><span>Click
                            Here</span></button>
                      </div>

                    </div>
                  </mat-card>
                </div>
                <div class="col-6">
                  <mat-card class="card-mat physical-activity">
                    <div class="wellness-cards-cont physical-activity">
                      <div class="wellness-icon-img-cont">
                        <img class="wellness-icon-img physical-activity" mat-card-image [src]="pa_icon" alt="card image">
                      </div>
                      <div class="wellness-icon-txt-cont">
                        <p class="wellness-icon-txt">Physical Activity</p>
                      </div>
                      <div class="wellness-icon-btn-cont">
                        <button type="button" class="recog-button " (click)="selectCategory('pa')"> <span>Click
                            Here</span></button>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
              <div class="row">
                <div class="col-6 offset-3">
                  <mat-card class="card-mat nutrition">
                    <div class="wellness-cards-cont nutrition">
                      <div class="wellness-icon-img-cont">
                        <img class="wellness-icon-img nutrition" mat-card-image [src]="nu_icon" alt="card image">
                      </div>
                      <div class="wellness-icon-txt-cont">
                        <p class="wellness-icon-txt">Nutrition</p>
                      </div>
                      <div class="wellness-icon-btn-cont">
                        <button type="button" class="recog-button " (click)="selectCategory('nu')"> <span>Click
                            Here</span></button>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-card class="card-mat financial-wellness">
                    <div class="wellness-cards-cont ">
                      <div class="wellness-icon-img-cont">
                        <img class="wellness-icon-img" mat-card-image [src]="fw_icon" alt="card image">
                      </div>
                      <div class="wellness-icon-txt-cont">
                        <p class="wellness-icon-txt">Financial Wellness</p>
                      </div>
                      <div class="wellness-icon-btn-cont">
                        <button type="button" class="recog-button " (click)="selectCategory('fw')"><span>Click
                            Here</span></button>
                      </div>

                    </div>
                  </mat-card>
                </div>
                <div class="col-6">
                  <mat-card class="card-mat book-recom">
                    <div class="wellness-cards-cont book-recom">
                      <div class="wellness-icon-img-cont">
                        <img class="wellness-icon-img book-recom" mat-card-image [src]="br_icon" alt="card image">
                      </div>
                      <div class="wellness-icon-txt-cont">
                        <p class="wellness-icon-txt book-recom">Book Recommendations</p>
                      </div>
                      <div class="wellness-icon-btn-cont">
                        <button type="button" class="recog-button " (click)="selectCategory('br')"> <span>Click
                            Here</span></button>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card-mat">
                <div class="outer-wellness-cards-cont">
                  <div class="row">
                    <div class="col-6 ">
                      <div class="wellness-cards-cont mental-health">
                        <img class="mental-health-image" mat-card-image [src]="mh_icon" alt="card image">
                        <button type="button" id="recog-button" class="btn btn-primary" router-link="/"> Click Here </button>
                      </div>
                    </div>
                    <div class="col-6 ">
                      <div class="wellness-cards-cont mental-health">
                        <img class="physical-activity-image" mat-card-image [src]="pa_icon" alt="card image">
                        <button type="button" id="recog-button" class="btn btn-primary" router-link="/"> Click Here </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
        </div>

        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 mt-0 mt-4 mt-md-0" 
        >
          <div class="row">
            <div class="col-12">
              <div class="flower-design-cont">
                <img class="flower-design" [src]="flowerGraphic" alt="flower design image">
              </div>
            </div>
          </div>
        </div>







        <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-8 mt-0 mt-4 mt-md-0">
            
              <jz-common-post-list [listOfPosts2]="listOfPosts2" [resources2]="resources2" [totalPages]="totalPages"
                                  [page]="page" [cat]="cat"></jz-common-post-list>
            </div> -->
      </div>
    </div>
   

  </div>
</div>
