import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PostsService} from '../core/posts.service';
import {ActivatedRoute} from '@angular/router';
import {HrCategory} from '../core/models/hr-category';
import {Resources2Service} from '../core/resources2.service';
import {IResource2} from '../core/models/resource2';
import {HrTeamListService} from '../core/hr-team-list.service';
import {Posts2} from '../core/models/paginatedPosts2ListResponse';
import {Posts2Service} from '../core/posts2.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {FetchingDataService} from '../new-org-chart/services/fetching-data.service';
import {Employee} from '../new-org-chart/models/employee';

@Component({
  selector: 'jz-security-operations',
  templateUrl: './security-operations.component.html',
  styleUrls: ['./security-operations.component.scss']
})

export class SecurityOperationsComponent implements OnInit, AfterViewInit {
  cat = 'Security Operations';
  errorMessage;
  securityCategories: HrCategory[];
  securityOperationsDocumentsArray: HrCategory[];
  policiesAndForms: HrCategory[];
  directorImg = '../../assets/security-operations/dir-image.png';
  staff: {name: string, job_title: string, profile_picture_url: string}[];
  posts2List: Posts2[];
  resources2: IResource2[];
  dataLoaded = false;
  jazmanianPolice = '../../assets/security-operations/jazmanian-police.png';

  // for accordion
  panelOpenState = false;

  @ViewChild('securityOperationsContainer') securityOperationsContainer: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private resourceService: Resources2Service,
    private posts2Service: Posts2Service,
    private spinner: NgxSpinnerService,
  ) {
    this.staff = [{name: "Rob Cassitta", job_title: "Dir, SecurityOps. & Administration", profile_picture_url: this.directorImg}];
  }

  ngOnInit(): void {
    // this.route.data.subscribe((data: { hrCategories: HrCategory[] }) => {
    //   this.securityOperationsDocumentsArray = data.hrCategories.filter(hrCategory => hrCategory.cat === "Payroll");
    //   console.log("doc array", this.securityOperationsDocumentsArray);
    // });
  //   this.resourceService.getResources2ByCat(this.cat).subscribe({
  //     next: response => {
  //       if (response.length) {
  //         this.securityCategories = response;
  //       }
  //     }
  //   }
  // );
    this.fetchData();
    this.fetchPosts2();
  }

  ngAfterViewInit() {
    // ElementRef { nativeElement: <input> }
    // console.log('height', this.hrContainer.nativeElement.style.height);
  }

  // private functions below
  private fetchData() {
    this.spinner.show();
    // this.securityOperationsDocumentsArray = this.securityCategories.filter(securityCategory => securityCategory.cat === 'Payroll');
    this.resourceService.getResources2ByCat(this.cat).subscribe({
      next: response => {
        if (response.length) {
          this.securityCategories = response;
        }
      }
    }
  );
    this.dataLoaded = true;
    this.spinner.hide();
  }

  private fetchPosts2() {
    // this.posts2Service.getPosts2ByCat(this.cat).subscribe({
    //   next: response => {
    //     if (response.length) {
    //       console.log("posts2", response)
    //       this.posts2List = response[0].posts;
    //     }
    //   },
    //   error: err => this.errorMessage = err
    // });
    this.posts2Service.getPosts2PaginatedByCategory(this.cat, 1).subscribe({
      next: response => {
        let paginatedPosts2ListResponse = response;
        console.log("paginatedPosts2ListResponse", paginatedPosts2ListResponse);
        this.posts2List = paginatedPosts2ListResponse.posts;
        // this.totalPages = this.paginatedPosts2ListResponse.total_pages;
        this.spinner.hide();
      },
      error: err => this.errorMessage = err
    });
  }

}


